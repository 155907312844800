<template>
    <div>
        <webHeader :routerName="routerName"/>
        <router-view @getCurrentRouter="getCurrentRouter"/>
        <div :class="isOpenLoginDialog === true?'sign-show':''">
            <loginDialog ref="loginDialog"/>
        </div>
        <musicPlayer v-show="isOpenMusicPlayer"/>
        <web-robot/>
        <web-footer/>
    </div>
</template>

<script>
	import {
		mapGetters,
		mapMutations
	} from 'vuex'
    import {getCatch,setCatch} from "@/utils";
    import webHeader from "@/pages/header/header.vue"
    import webRobot from  "@/pages/robot/index.vue"
    import webFooter from "@/pages/footer/footer.vue"
    import loginDialog from "@/pages/login/login.vue"
    import musicPlayer from "@/pages/player/music.vue"
    export default {
        name: "App",
        components: {
            webHeader,
            musicPlayer,
            webRobot,
            webFooter,
            loginDialog
        },
        data() {
            return {
                routerName:''
            }
        },
        methods:{
            ...mapMutations(['setOpenMusicPlayer','setOpenLoginDialogState']),
            getCurrentRouter(val){
                this.routerName = val
            }
        },
        computed: {
			...mapGetters(['isOpenMobileProp','isOpenLoginDialog','isOpenMusicPlayer'])
        },
        watch: {
            isOpenLoginDialog(val) {
                if (val) {
                    this.setOpenMusicPlayer(false)
                    document.querySelector("body").className = 'open'
                } else {
                    if (this.isOpenMobileProp){
                        if (this.isOpenLoginDialog){
                            document.querySelector("body").className = ''
                        }
                    }else {
                        document.querySelector("body").className = ''
                    }
                    this.setOpenMusicPlayer(true)
                }
            }
        },
        mounted(){
            let _this = this
            let bindAccount = window.localStorage.getItem("bind-account")
            if (bindAccount){
                _this.$confirm('检测到你上次未操作完绑定账号', '提示', {
                    confirmButtonText: '继续绑定',
                    cancelButtonText: '取消',
                    type: 'warning'
                }).then(() => {
                    _this.setOpenLoginDialogState(true)
                }).catch(() => {
                    window.localStorage.removeItem("bind-account")
                    if (_this.$refs.loginDialog){
                        _this.$refs.loginDialog.toLoginWin()
                    }
                });
            }
        }
    }
</script>

<style scoped>

</style>