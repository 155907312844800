import Vue  from 'vue'
import router from './router'
import App from './App.vue'
import store from './store'
import Element from 'element-ui'
import waterfall from 'vue-waterfall2'
import VueLazyload from 'vue-lazyload'
import MetaInfo from 'vue-meta-info'
import 'element-ui/lib/theme-chalk/index.css'
import "@/assets/css/animate.css"
import "@/assets/css/font-awesome.min.css"
import "@/assets/css/style.css"
Vue.use(MetaInfo)
Vue.use(waterfall)
Vue.use(Element)
Vue.use(VueLazyload,{
    preLoad: 1.3,
    error: require('@/assets/image/avatar.png'),
    loading: require('@/assets/image/logo-loading.gif'),
    attempt: 1
})
new Vue({
    router,
    store,
    render: h => h(App),
    mounted() {
        document.dispatchEvent(new Event('render-event'))
    }
}).$mount('#app')