<template>
	<div class="footer bg-dark">
		<div class="footer-top">
			<a target="_blank" href="https://beian.mps.gov.cn/#/query/webSearch">
				<img src="https://pic3.zhimg.com/80/v2-d0289dc0a46fc5b15b3363ffa78cf6c7.png"/>
				{{footerBusinessBei}}
			</a>
		</div>
		<div class="footer-container">
			<div class="footer-center">
				<a class="beian-ico" target="_blank" href="https://beian.miit.gov.cn/#/Integrated/index">{{footerIcpBei}}</a>
				<span id="iday">已安全运行{{siteCreateTime}}</span>
			</div>
			<div class="footer-right">
				<span id="cday">{{currentTime}}</span>
			</div>
		</div>
	</div>
</template>

<script>
	import {mapGetters} from 'vuex'
	import {stringUtil} from '@/utils/index.js'
	export default {
		name: "web-footer",
		data() {
			return {
				siteCreateTime: '',
				currentTime:''
			}
		},
		computed: {
			...mapGetters(['footerBusinessBei','footerIcpBei', 'isOpenLoginDialog']),
		},
		methods:{

		},
		created() {
			var _this = this
			var creDate = new Date('2019-07-03')
			_this['timeObj'] = setInterval(function() {
				var curDate = new Date()
				var diff = curDate.getTime() - creDate.getTime()
				//计算出相差天数 
				var days=Math.floor(diff/(24*3600*1000)); 
				//计算出小时数 
				var leave1=diff%(24*3600*1000);    //计算天数后剩余的毫秒数 
				var hours=Math.floor(leave1/(3600*1000)); 
				//计算相差分钟数 
				var leave2=leave1%(3600*1000);        //计算小时数后剩余的毫秒数 
				var minutes=Math.floor(leave2/(60*1000)); 
				//计算相差秒数 
				var leave3=leave2%(60*1000);      //计算分钟数后剩余的毫秒数 
				var seconds=Math.round(leave3/1000); 
				var returnStr = seconds + "秒"; 
				if(minutes>0) { 
					returnStr = minutes + "分" + returnStr; 
				} 
				if(hours>0) { 
					returnStr = hours + "小时" + returnStr; 
				}
				if(days>0) { 
					returnStr = days + "天" + returnStr; 
				}
				_this.siteCreateTime = returnStr
                _this.currentTime = stringUtil.formatDate('yyyy-MM-dd hh:mm:ss',curDate)
			}, 1000)
		}
	}
</script>

<style scoped lang="less">
	iday {margin:0 3px;}
	.bg-dark {
		padding: 10px 0px;
		background-color: rgba(8,8,8,0.68);
		border-top: 2px solid rgba(70,147,233,0.78);
		color: #f5f5f5;
		position: fixed;
		bottom: 0;
		width: 100%;
		z-index: 10;
		a {color:#f5f5f5;}
	}
	.footer-top{
		text-align: center;
		margin-bottom: 5px;
	}
	.footer-container{
		width: 100%;
	}
	.footer-center {
		text-align: center;
		display: flex;
		justify-content: center;
		float: left;
		position: relative;
		left: 50%;
		transform: translate(-50%,0%);
	}
	.footer-right{
		margin-right: 20px;
		cursor: pointer;
		width: max-content;
		float: right;
	}
</style>
