<template>
    <div>
        <div v-show="!isOpenLoginDialog" id="backtop" class="backtop">
            <div @click="toTopScroll" id="bt-box-btn" class="bt-box"><i
                    class="icon fa  fa-long-arrow-up"></i>
            </div>
            <!--<div class="bt-box bt_night"><a href="javascript:switchNightMode()"-->
                                            <!--target="_self" class="at-night"><i-->
                    <!--class="icon fa fa-cog"></i></a>-->
            <!--</div>-->
            <!--<div class="bt-box bottom"><i class="icon fa fa-long-arrow-down"></i>-->
            <!--</div>-->
        </div>
        <a href="javascript:;" rel="noopener noreferrer" title="AI秘书" class="bt-service"><span>微信公众号搜索：<br>“数字凡人”
		</span></a>
    </div>

</template>

<script>
    import {mapGetters} from 'vuex'

    export default {
        name: "web-robot",
        data() {
            return {}
        },
        computed: {
            ...mapGetters(['isOpenLoginDialog'])
        },
        methods: {
            switchNightMode() {

            },
            toTopScroll(){
                window.scrollTo({
                    top: 0 ,
                    behavior: "smooth"
                })
            }
        }
    }
</script>

<style scoped>
    a.bt-service:hover {
        right: 2px;
        -webkit-transform: rotate(0deg);
        -ms-transform: rotate(0deg);
        transform: rotate(0deg);
    }

    footer.bg-dark a {
        color: #f5f5f5;
    }

    a.bt-service {
        display: block;
        width: 56px;
        position: fixed;
        bottom: 40px;
        z-index: 12;
        background: url(../../assets/image/ai-service.png) 50% no-repeat;
        background-size: 100%;
        height: 87px;
        margin-bottom: 20px;
        -webkit-transition: all .3s;
        transition: all .3s;
        right: -10px;
        -webkit-transform: rotate(-25deg);
        -ms-transform: rotate(-25deg);
        transform: rotate(-25deg);
        text-decoration: none;
    }
    @keyframes top-btn-ani {
        0%{
            height: 0px;
        }
        30%{
            height: 10px;
        }
        60%{
            height: 25px;
        }
        100%{
            height: 36px;
        }
    }
    .top-btn-btn{
        display:block !important;
        animation-name:top-btn-ani;
        animation-duration: 300ms;
    }
    #bt-box-btn{
        display: none;
    }
</style>