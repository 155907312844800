import Vue from 'vue'
import {getUserInfo} from '@/api/user';
import VueRouter from 'vue-router'
import {setCatch,objectIsNull,showLoading,getUrlParam,isEmpty,setToken} from '@/utils'
import {getWxToken} from '@/api/auth'
Vue.use(VueRouter)
//获取原型对象上的push函数
const originalPush = VueRouter.prototype.push
//解决跳转加参数报错问题
VueRouter.prototype.push = function push(location) {
    return originalPush.call(this, location).catch(err => err)
}
// 修改路由replace方法,阻止重复点击报错
const originalReplace = VueRouter.prototype.replace;
VueRouter.prototype.replace = function replace(location) {
  return originalReplace.call(this, location).catch(err => err)
}
const routes = [
    {
        path:"/",
        redirect:"/home"
    },
    {
        path: '/home', name: 'home',
        component: resolve => { require(['@/pages/home/index.vue'], resolve) },
        meta:{
            title:""
        }
    },
    {
        path: '/about', name: 'about',
        component: resolve => { require(['@/pages/home/about.vue'], resolve) },
        meta:{
            title:"关于本站"
        }
    },
    {
        path: '/tools', name: 'tools',
        component: resolve => { require(['@/pages/home/tools.vue'], resolve) },
        meta:{
            title:"实用工具"
        }
    },
    {
        path:'/music',name:'music_detail',
        component: resolve => { require(['@/pages/player/music_detail.vue'], resolve) },
        meta:{
            title:'歌曲详情',
            isSaveParam:true
        }
    },
    {
        path:'/user',name:'user_center',
        component: resolve => { require(['@/pages/user/user_center.vue'], resolve) },
        meta:{
            title:'用户中心'
        }
    },
    {
        path:'/orders',name:'user_order',
        component: resolve => { require(['@/pages/user/user_order.vue'], resolve) },
        meta:{
            title:'用户中心'
        }
    },
    {
        path:'/create_article',name:'create_article',
        component: resolve => { require(['@/pages/article/create-article.vue'], resolve) },
        meta:{
            title:'发布文章'
        }
    },
    {
        path:'/article_detail',name:'article_detail',
        component: resolve => { require(['@/pages/article/article-detail.vue'], resolve) },
        meta:{
            title:'文章详情',
			isSaveParam:true
        }
    },
    {
        path:'/friend-link',name:'friendLink',
        component: resolve => { require(['@/pages/article/friend-link.vue'], resolve) },
        meta:{
            title:'友情链接',
            isSaveParam:true
        }
    },
    {
        path:'/user_pass',name:'userPass',
        component: resolve => { require(['@/pages/user/user_pass.vue'], resolve) },
        meta:{
            title:'账号密码'
        }
    },
    {
        path:'/qq_login',name:'qq_login',
        component: resolve => { require(['@/pages/user/qq_login.vue'], resolve) },
        meta:{
            title:'qq登陆回调'
        }
    },
    {
        path:'/wxLogin:winName',name:'wxLogin',
        component: resolve => { require(['@/pages/user/wx_login.vue'], resolve) },
        meta:{
            title:'wx登陆回调'
        }
    },
    {
        path:'/integral_history',name:'integral_history',
        component: resolve => { require(['@/pages/user/integral_history.vue'], resolve) },
        meta:{
            title:'积分历史'
        }
    }
]
const router = new VueRouter({
    mode: 'history',
    scrollBehavior() {
        return {
            x: 0,
            y: 0
        }
    },
    routes
})
const toPage = (to, from, next) => {
    if (router.app.$store.getters.isLogin != router.app.$store.state.isLogin){
        router.app.$store.commit('setLoginState',router.app.$store.getters.isLogin)
    }
    if (to.matched[0].meta.isSaveParam){
        //处理params路由形式跳转页面参数丢失问题
        if (!objectIsNull(to.params)){
            setCatch(to.name,to.params)
        }
        if(!objectIsNull(to.query)){
            for(var i in to.query){
                to.params[i] = to.query[i]
            }
            setCatch(to.name,to.query)
        }
        if (to.name != from.name){
            showLoading()
        }
        if (!objectIsNull(to.params) && Object.keys(to.query).length === 0) {
            for(var i in to.params){
                to.query[i] = to.params[i]
            }
            next({
                path:to.path,
                query: to.query
            })
        } else {
            if (to.params.title){
                document.title = to.params.title
            }
            next()
        }
    }else {
        showLoading()
        next()
    }
}
router.beforeEach((to, from, next) => {
    if(to.params.title){
        document.title = to.params.title
    } else if (to.matched[0].meta && to.matched[0].meta.title){
        document.title = '数字凡人-'+to.matched[0].meta.title
    }else{
        document.title = '数字凡人'
    }
    if (router.app.$store.getters.isLogin){
        let userInfo = router.app.$store.getters.userInfo
        if (isEmpty(userInfo)){
            getUserInfo().then(res=>{
                router.app.$store.commit('setUserInfo',res.user)
                toPage(to,from,next)
            },err=>{
                router.app.$store.commit('setLoginState',false)
                toPage(to,from,next)
            })
        }else {
            toPage(to,from,next)
        }
    }else {
        toPage(to,from,next)
    }
})
router.onError((error) => {
    const pattern = /Loading chunk (\d)+ failed/g;
    const isChunkLoadFailed = error.message.match(pattern);
    const targetPath = router.history.pending.fullPath;
    if (isChunkLoadFailed) {
        router.replace(targetPath);
    }
});
export default router
