import axios from 'axios'
import store from "@/store"
import { getToken,setToken } from '@/utils'
import {Message} from 'element-ui'
// 创建axios实例
const service = axios.create({
    baseURL: process.env.NODE_ENV === 'production' ? process.env.VUE_APP_BASE_API : '/',
    timeout: 100000 // 请求超时时间
})
// request拦截器
service.interceptors.request.use(
    config => {
        if (getToken()) {
            config.headers['Authorization'] = getToken() // 让每个请求携带自定义token 请根据实际情况自行修改
        }
        config.headers['Content-Type'] = 'application/json'
        config.headers['Device-Model'] = 'web-'
        return config
    },
    error => {
        Promise.reject(error)
    }
)

// response 拦截器
service.interceptors.response.use(
    response => {
        return response.data
    },
    error => {
        let code = 0
        try {
            code = error.response.data.status
        } catch (e) {
            if (error.toString().indexOf('Error: timeout') !== -1) {
                Message.error('网络请求超时')
                return Promise.reject(error)
            }
            if (error.toString().indexOf('Error: Network Error') !== -1) {
                Message.error('网络请求错误')
                return Promise.reject(error)
            }
        }
        if (code === 401) {
            Message.error('登陆信息过期')
            store.commit("setLoginState",false)
        }else if(code === 404){
            Message.error('未找到对应资源')
        }  else {
            const errorMsg = error.response.data.message
            if (errorMsg !== undefined) {
                Message.error(errorMsg)
            }
        }
        return Promise.reject(error)
    }
)
export default service