import Vue from "vue"
import Vuex from "vuex"
import createPersistedState from "vuex-persistedstate"

import state from "./state"
import getters from "./getters"
import actions from "./actions"
import mutations from "./mutations"

Vue.use(Vuex)
const store = new Vuex.Store({
    state,
    getters,
    actions,
    mutations,
    plugins: [createPersistedState({
        storage: window.sessionStorage,
        reducer(val) {
            return {
                //持久化isLogin属性(页面刷新后vuex store中mutations获取值为空的问题)
                isLogin: val.isLogin
            }
        }
    })]
})
export default store
