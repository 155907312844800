<template>
    <div v-if="musicPlayer.playData && musicPlayer.playData.length > 0"  id="myhkplayer" style="background: rgba(78, 55, 80, 0.8);"
         :class="isShowPlayerDialog === true?'showAlbumList show':'showAlbumList'">
        <div class="player">
            <canvas class="blur-img" width="300" height="155" id="canvas">
                您的浏览器不支持canvas，请更换高级版的浏览器！
            </canvas>
            <div class="blur-img">
                <img :src="musicPlayer.playData[musicPlayer.playIndex].pic" class="blur" style="top: 0px; display: inline;">
            </div>
            <div class="infos" style="color: rgb(255, 255, 255);">
                <div class="songstyle"><i class="fa fa-music"></i>
                    <div class="name"><span class="song"><span :title="musicPlayer.playData[musicPlayer.playIndex].name"
                                                               v-text="musicPlayer.playData[musicPlayer.playIndex].name"></span></span></div>
                </div>
                <div class="timestyle"><i class="fa fa-clock-o"></i> <span class="time"
                                                                           v-text="musicData.cur+'/'+musicData.len"></span>
                </div>
                <div class="artiststyle"><i class="fa fa-user"></i>
                    <span class="artist">
                        <span :title="musicPlayer.playData[musicPlayer.playIndex].author" v-text="musicPlayer.playData[musicPlayer.playIndex].author"></span>
                    </span>
                    <span class="moshi"><i :class="$options.filters.playTypeIcon(musicPlayer.playType)"></i> {{musicPlayer.playType|playTypeText}}</span>
                </div>
                <div class="artiststyle"><i class="fa fa-info-circle"></i>
                    <span class="artist1" :title="musicPlayer.playData[musicPlayer.playIndex].album"><span v-text="musicPlayer.playData[musicPlayer.playIndex].album"></span></span>
                    <span class="myhkgeci"><span class="geci"><i class="fa fa-check-circle"></i> 歌词开启</span></span>
                </div>
            </div>
            <div class="control" style="color: rgb(255, 255, 255);">

                <i @click="preClick" class="prev fa fa-backward" title="上一首"></i>
                <div @click="playPause" class="status"
                     style="background-image: linear-gradient(90deg, rgb(255, 255, 255) 50%, transparent 50%, transparent), linear-gradient(90deg, rgb(255, 255, 255) 50%, rgb(255, 255, 255) 50%, rgb(255, 255, 255));">
                    <b style="background: rgba(78, 55, 80, 0.5);">
                        <i :class="musicPlayer.playState == 3?'pause fa fa-pause-circle':'play fa fa-play-circle'"></i>
                    </b>

                </div>
                <i @click="nextClick" class="next fa fa-forward" title="下一首"></i>
            </div>
            <div class="musicbottom" id="playerBottom">
                <div class="volumecontrol">
                    <div class="volume fa fa-volume-down"></div>
                    <div class="volumeprogress">
                        <el-slider v-model="volume" @change="onVolumeClick" vertical height="100px" :show-tooltip="false" vertical  input-size="mini" :min="0" :max="100"></el-slider>
                    </div>
                </div>
                <div class="playprogress">
                    <div @click="processClick($event)" class="progressbg">
                        <div class="progressbg1" :style="'width:'+musicPlayer.progress"></div>
                        <div class="progressbg2"></div>
                        <div @mousemove="progressMove($event)" @mouseleave="progressLeave($event)" class="ts"
                             :style="'left:'+musicPlayer.progress"></div>
                    </div>
                </div>
                <div class="switch-playlist" @click="toDetail">
                    <i class="fa fa-list-ul" title="音乐详情"></i>
                </div>
                <div class="qhms" @click="changePlayType">
                    <i :class="$options.filters.playTypeIcon(musicPlayer.playType)" :title="$options.filters.playTypeText(musicPlayer.playType)"></i>
                </div>
                <div class="switch-ksclrc">
                    <i class="fa fa-toggle-on" title="关闭歌词"></i>
                </div>
            </div>
            <div :class="musicPlayer.playState == 3?'cover coverplay':'cover'"><img
                    :src="musicPlayer.playState == 1?require('@/assets/image/logo-loading.gif'):musicPlayer.playData[musicPlayer.playIndex].pic"
                    style="border: 4px solid rgb(255, 255, 255);"></div>
        </div>
        <div class="switch-player" style="background: rgba(78, 55, 80, 0.3);" @click.stop="openPlayer">
            <i :class="isShowPlayerDialog === true?'fa fa-angle-right':'fa fa-music'" style="margin-top: 20px;"></i>
        </div>
    </div>
</template>
<script>
    import {mapGetters,mapMutations} from "vuex"
    import {getCatch,setCatch,transTime,calcPageCount,showLoading,hideLoading} from "@/utils";
    import {getList} from "@/api/music";

    export default {
        name: "player",
        data() {
            return {
                isShowPlayerDialog: false,//是否展示播放器
                page:{
                    count:0,
                    pageNum:0,
                    pageSize:100,
                    pageCount:0
                },
                audio: null,
                volume:60,
                musicData:{
                    cur:'00:00',
                    len:'00:00',
                    name:'',
                    src:'',
                    pic:'',
                    album:'',
                    author:''
                }
            }
        },
        methods: {
            ...mapMutations(['setMusicPlayer']),
            getData(){
                var _this = this
                getList({page:_this.page.pageNum,size:_this.page.pageSize}).then(function (ret) {
                    _this.page.pageCount = calcPageCount(ret.totalElements,_this.page.pageSize)
                    let musicData =getCatch('music_detail'),index=0
                    if (musicData){
                        let id = musicData.id
                        for(let i=0;i<ret.content.length;i++){
                            if (id == ret.content[i].id){
                                index = i
                                break
                            }
                        }
                        _this.setMusicPlayer({
                            playData : ret.content,
                            playIndex: index,
                            volume:musicData?musicData.volume:50
                        })
                    }else {
                        _this.setMusicPlayer({
                            playData : ret.content,
                            playIndex: index,
                            volume:musicData?musicData.volume:50
                        })
                        setCatch('music_detail',{id:_this.musicPlayer.playData[index].id,volume:50})
                    }
                },function (err) {

                })
            },
            openPlayer() {
                this.isShowPlayerDialog = !this.isShowPlayerDialog
            },
            /**
             * 上一首
             */
            preClick() {
                var _this = this,index = 0
                if (_this.musicPlayer.playIndex === 0) {
                    index = _this.musicPlayer.playData.length - 1
                } else {
                    index = _this.musicPlayer.playIndex - 1
                }
                _this.setMusicPlayer({
                    playState:0,
                    startPlay:_this.musicPlayer.playState == 3?true:false,
                    playIndex:index
                })
                setCatch('music_detail',{id:_this.musicPlayer.playData[index].id,volume:_this.musicPlayer.volume})
            },
            /**
             * 下一首
             */
            nextClick() {
                var _this = this,index=0
                if (_this.musicPlayer.playIndex === (_this.musicPlayer.playData.length - 1)) {
                    index = 0
                } else {
                    index = _this.musicPlayer.playIndex + 1
                }
                _this.setMusicPlayer({
                    playState:0,
                    startPlay:_this.musicPlayer.playState == 3?true:false,
                    playIndex:index
                })
                setCatch('music_detail',{id:_this.musicPlayer.playData[index].id,volume:_this.musicPlayer.volume})
            },
            /**
             * 切换播放模式
             */
            changePlayType(){
                var _this = this
                if (_this.musicPlayer.playType === 2){
                    _this.musicPlayer.playType = 0
                } else {
                    _this.musicPlayer.playType += 1
                }
            },
            progressMove($event) {
                //console.log($event)
            },
            progressLeave($event) {
                //console.log($event)
            },
            /**
             * 点击播放进度条
             * @param $event
             */
            processClick($event) {
                var _this = this
                var x = $event.pageX
                var bgL = $event.currentTarget.offsetLeft
                var bgW = $event.currentTarget.offsetWidth
                var a = x - bgL
                var l = (a / bgW).toFixed(2)
                _this.seeking(l)
            },
            /**
             * 点击音量控制
             * */
            onVolumeClick(val){
                if (this.audio){
                    this.audio.volume = parseFloat((val / 100).toFixed(1))
                }
            },
            /**
             * 移动音量控制进度条
             */
            onVolumeMove($event){
                var _this = this
                var y = $event.pageY
                var bgT = $event.currentTarget.offsetTop
                var bgH = $event.currentTarget.offsetHeight
            },
            /**
             * 移动音量控制进度条
             */
            onVolumeLeave($event){
                var _this = this
                var y = $event.pageY
                var bgT = $event.currentTarget.offsetTop
                var bgH = $event.currentTarget.offsetHeight
            },
            /**
             * 设置播放位置
             * @param l 要设置的百分比
             */
            seeking(l) {
                var _this = this
                var durTime = _this.audio.duration
                var seekTime = durTime * l
                _this.audio.currentTime = seekTime
            },
            /**
             * 播放暂停控制
             */
            playPause() {
                var _this =this
                if (_this.musicPlayer.playState == 3) {
                    _this.audio.pause()
                } else {
                    _this.audio.play()
                }
            },
            toDetail() {
                var _this = this
                var detail = _this.musicPlayer.playData[_this.musicPlayer.playIndex]
                this.isShowPlayerDialog = false
                this.$router.push({name: 'music_detail', params: {id:detail.id}})
            }
        },
        filters: {
            playTypeIcon(playType){
                if (playType === 0){
                    return 'fa fa-retweet'
                } else if (playType === 1){
                    return 'fa fa-refresh'
                } else if (playType === 2){
                    return 'fa fa-random'
                } else {
                    return 'fa fa-retweet'
                }
            },
            playTypeText(playType) {
                if (playType === 0){
                    return '列表循环'
                } else if (playType === 1){
                    return '单曲循环'
                } else if (playType === 2){
                    return '随机播放'
                } else {
                    return '列表循环'
                }
            }
        },
        computed: {
            ...mapGetters(['musicPlayer'])
        },
        watch:{
            musicPlayer:{
                handler(newVal,oldVal){
                    var _this = this
                    var src = _this.audio.src
                    if (newVal.playData.length>0){
                        if (src !== newVal.playData[newVal.playIndex].url){
                            _this.audio.src = newVal.playData[newVal.playIndex].url
                            if (newVal.startPlay === true){
                                _this.$nextTick(function () {
                                    _this.audio.play()
                                })
                            }
                        }
                    }
                },
                immediate: false,
                deep:true
            }
        },
        created() {
            var _this = this
            _this.audio = new Audio()
            _this.audio.addEventListener("timeupdate", function () {
                var curTime = _this.audio.currentTime
                var durTime = _this.audio.duration
                if (!isNaN(durTime)) {
                    _this.musicPlayer.progress = ((curTime / durTime * 100).toFixed(2)) + "%"
                    _this.musicData.len = transTime(durTime)
                    _this.musicData.cur = transTime(curTime)
                    _this.musicPlayer.progressTime = _this.musicData.cur
                }
            });
            _this.audio.addEventListener("playing", function () {
                _this.setMusicPlayer({
                    playState:3,
                    startPlay:false
                })
            });
            _this.audio.addEventListener("play", function () {

            });
            _this.audio.addEventListener("pause", function () {
                //防止播放完毕时也进入此监听
                if (_this.audio.currentTime !== _this.audio.duration){
                    _this.setMusicPlayer({
                        playState:0,
                        startPlay:false
                    })
                }
            })
            _this.audio.addEventListener("ended", function () {
                if (_this.musicPlayer.playType === 0){
                    console.log("列表循环")
                    _this.nextClick()
                } else if (_this.musicPlayer.playType === 1){
                    console.log("单曲循环")
                    _this.audio.currentTime = 0
                    _this.audio.play()
                } else if (_this.musicPlayer.playType === 2){
                    console.log("随机播放")
                }
            })
            _this.audio.addEventListener("loadstart",function () {
                _this.audio.volume = (_this.musicPlayer.volume / 100).toFixed(1)
                _this.setMusicPlayer({
                    playState:1
                })
            })
            _this.audio.addEventListener("loadedmetadata",function () {
                _this.setMusicPlayer({
                    playState:2
                })
            })
            _this.audio.addEventListener("error", function () {
                _this.setMusicPlayer({
                    playState:0
                })
            })
            _this.audio.volume = parseFloat((_this.volume / 100).toFixed(1))
            _this.getData()
        }
    }
</script>
<style lang="less">
    .volumeprogress{
        .el-slider__runway{
            margin: 0px 10px!important;
        }
    }
</style>
<style scoped>
    #myhkLrc li, #myhkLrc ul, #myhkplayer .playlist .list li, #myhkplayer .playlist .list ul {
        margin: 0;
        padding: 0;
        list-style: none;
        background-color: unset
    }

    #myhkplayer .fa {
        width: unset;
        color: unset;
        padding: unset;
        position: unset;
        margin: unset;
        background: unset;
        font-family: FontAwesome !important;
        font-size: unset;
        top: unset;
        opacity: unset
    }

    #myhkplayer a, #myhkplayer span, #myhkLrc li span {
        display: inline !important;
        text-decoration: none !important;
        border: unset;
        color: unset !important;
        font-size: unset;
    }

    #myhkplayer {
        display: block;
        font: small 'Microsoft Yahei';
        letter-spacing: normal;
        -webkit-transition: .5s;
        -moz-transition: .5s;
        -ms-transition: .5s;
        transition: .5s;
        left: -300px;
        text-align: left;
        color: #38343e;
        width: 300px;
        position: fixed;
        bottom: 45%;
        box-shadow: 0 0 5px 5px rgba(0, 0, 0, .2);
        border-radius: 5px;
        z-index: 99999999;
        margin: 0;
        padding: 0;
        list-style: none;
        font-family: "Microsoft YaHei", "微软雅黑", "Trebuchet MS", Arial, Verdana, Tahoma, sans-serif
    }

    #myhkplayer .player {
        width: unset;
        font-family: unset;
        position: relative;
        overflow: hidden;
        background-color: rgba(0, 0, 0, .1);
        height: 180px;
        border-radius: 5px 5px 0 0
    }

    #myhkplayer .player .cover {
        -webkit-user-select: none;
        -moz-user-select: none;
        -ms-user-select: none;
        user-select: none;
        position: absolute;
        background-color: #fff;
        width: 110px;
        height: 110px;
        top: 50%;
        left: 50%;
        border-radius: 50%;
        box-shadow: 0 0 20px rgba(0, 0, 0, 0.3);
        margin-top: -67px;
        margin-left: -54px
    }

    #myhkplayer .player .cover:after {
        -moz-box-sizing: content-box;
        -webkit-box-sizing: content-box;
        -o-box-sizing: content-box;
        -ms-box-sizing: content-box;
        box-sizing: content-box;
        content: 'Music';
        position: absolute;
        opacity: .5;
        width: 100%;
        left: 0;
        text-align: center;
        top: 45px
    }

    #myhkplayer .player .cover.changing img {
        -webkit-transform: scale(.3);
        -moz-transform: scale(.3);
        -ms-transform: scale(.3);
        transform: scale(.3);
        opacity: 0
    }

    #myhkplayer .player .cover img {
        -webkit-transition: .5s;
        -moz-transition: .5s;
        -ms-transition: .5s;
        transition: .5s;
        border-radius: 50%;
        border: 4px #fff solid;
        top: -5px;
        left: -5px;
        width: 110px;
        height: 110px;
        max-width: 110px;
        max-height: 110px;
        position: relative;
        z-index: 10;
        margin: unset
    }

    #myhkplayer .player .infos, #myhkplayer .player .infos div, #myhkplayer .player .infos span, #myhkplayer .musicheader, #myhkplayer .musicheader a, #myhkplayer .playlist .list li, #myhkplayer .playlist .list li span {
        font-family: auto !important;
    }

    #myhkplayer .player .infos {
        font-size: 12px;
        position: absolute;
        top: 15px;
        right: 5px;
        left: 5px;
        color: #fff;
        line-height: unset;
        width: unset;
        background: unset;
        margin: unset
    }

    #myhkplayer .player .infos a {
        color: #fff;
        font-size: unset
    }

    #myhkplayer .player .infos a:hover {
        color: #81c300
    }

    #myhkplayer .player .infos .artiststyle {
        margin-top: 20px
    }
    #myhkplayer .player .infos .artiststyle .artist1,.artist{
        padding-left: 3px;
    }
    #myhkplayer .player .infos .moshi {
        float: right
    }

    #myhkplayer .player .infos .geci {
        float: right
    }

    #myhkplayer .player .infos .timestyle .time {
        color: unset !important;
        font-size: unset !important;
        background-color: unset !important;
        padding: unset !important;
        width: unset !important;
        float: unset !important;
        background-position: unset !important;
        background: unset !important;
        display: inline-block !important
    }

    #myhkplayer .player .infos .timestyle {
        float: right;
        margin-top: -15px;
        left: 5px;
        right: 5px
    }

    #myhkplayer .player .infos .songstyle .name {
        width: 80px;
        height: unset;
        color: unset;
        line-height: unset;
        text-align: unset;
        white-space: nowrap;
        overflow: hidden;
        vertical-align: text-top;
        display: inline-block;
        font-size: unset
    }

    #myhkplayer .player .infos .songstyle .song {
        width: 80px;
        padding-left: 3px;
    }

    #myhkplayer .player .infos .myhkgeci {
        display: unset !important;
        opacity: unset !important;
        height: unset !important;
    }

    #myhkplayer .player .control {
        font-family: unset;
        -webkit-user-select: none;
        -moz-user-select: none;
        -ms-user-select: none;
        user-select: none;
        bottom: -110px;
        left: 5px;
        text-shadow: none;
        position: relative;
        z-index: 99999;
        font-size: 22px;
        color: #fff
    }

    #myhkplayer .player .control .anext, #myhkplayer .player .control .aprev, #myhkplayer .player .control .next, #myhkplayer .player .control .prev {
        background-color: unset;
        float: unset;
        left: 94px;
        margin-right: 32px;
        cursor: pointer
    }

    #myhkplayer .musicheader a, #myhkplayer .myhkgeci a {
        cursor: pointer
    }

    #myhkplayer .musicheader a:hover, #myhkplayer .myhkgeci a:hover {
        color: red !important
    }

    #myhkplayer .player .control i.current {
        color: #81c300
    }

    #myhkplayer .player .control .next {
        position: relative;
        display: inline-block;
        vertical-align: middle;
        margin-left: 2px
    }

    #myhkplayer .player .control .next:hover {
        color: #81c300
    }

    #myhkplayer .player .control .aprev:hover {
        color: #81c300
    }

    #myhkplayer .player .control .prev:hover {
        color: #81c300
    }

    #myhkplayer .player .control .anext {
        position: relative;
        display: inline-block;
        vertical-align: middle
    }

    #myhkplayer .player .control .anext:hover {
        color: #81c300
    }

    #myhkplayer .player .control .status {
        cursor: pointer;
        position: relative;
        display: inline-block;
        vertical-align: middle;
        bottom: 54px;
        width: 44px;
        height: 44px;
        margin-left: 71px;
        border-radius: 50%;
        background-image: linear-gradient(90deg, #fff 50%, transparent 50%, transparent), linear-gradient(90deg, #FFF 50%, #fff 50%, #fff)
    }

    #myhkplayer .player .control .status b {
        position: absolute;
        width: 40px;
        height: 40px;
        font-size: 34px;
        text-align: center;
        border: solid #12A9EB 2px;
        top: 0;
        left: 0;
        border-radius: 50%;
        background-color: #2c2a30;
        display: inline-block;
        overflow: hidden;
        box-sizing: initial
    }

    #myhkplayer .player .control .status b .fa {
        display: block;
        width: 40px;
        height: 40px;
        line-height: 41px
    }

    #myhkplayer .player .control .status b .fa:hover {
        color: #12A9EB
    }

    #myhkplayer .player .control .status b .play {
        color: #12A9EB;
        -webkit-transition: .5s;
        -moz-transition: .5s;
        -ms-transition: .5s;
        transition: .5s
    }

    #myhkplayer .player .control .status b .play:hover {
        color: #81c300
    }

    #myhkplayer .player .control .status b .pause {
        -webkit-transition: .5s;
        -moz-transition: .5s;
        -ms-transition: .5s;
        transition: .5s
    }

    #myhkplayer .player .control .status b .pause:hover {
        color: #81c300
    }

    #myhkplayer .player .musicbottom {
        -webkit-user-select: none;
        -moz-user-select: none;
        -ms-user-select: none;
        user-select: none;
        position: absolute;
        background-color: #fff;
        text-shadow: none;
        height: 25px;
        width: 100%;
        left: 0;
        bottom: 0;
        line-height: 25px
    }

    #myhkplayer .player .musicbottom .switch-playlist {
        position: absolute;
        -webkit-transition: .5s;
        -moz-transition: .5s;
        -ms-transition: .5s;
        transition: .5s;
        top: 6px;
        line-height: 10px;
        right: 5px;
        font-size: 1.1em;
        cursor: pointer
    }

    #myhkplayer .player .musicbottom .switch-playlist:hover {
        color: #81c300
    }

    #myhkplayer .player .musicbottom .switch-ksclrc {
        position: absolute;
        -webkit-transition: .5s;
        -moz-transition: .5s;
        -ms-transition: .5s;
        transition: .5s;
        top: 6px;
        line-height: 10px;
        right: 30px;
        font-size: 1.2em;
        cursor: pointer
    }

    #myhkplayer .player .musicbottom .switch-ksclrc:hover {
        color: #81c300
    }

    #myhkplayer .player .musicbottom .qhms {
        position: absolute;
        -webkit-transition: .5s;
        -moz-transition: .5s;
        -ms-transition: .5s;
        transition: .5s;
        top: 6px;
        line-height: 10px;
        left: 30px;
        font-size: 1.2em;
        cursor: pointer
    }

    #myhkplayer .flat {
        position: absolute;
        top: 70px;
        left: 185px;
        color: #fff;
        font-size: 1.4em;
        opacity: 0
    }

    #myhkplayer .switch-player {
        position: absolute;
        top: 0px;
        right: -25px;
        width: 28px;
        height: 68px;
        line-height: 68px;
        text-shadow: none;
        background-color: rgba(0, 0, 0, .2);
        border-radius: 0 5px 5px 0;
        box-shadow: 1px 1px 1px 1px rgba(0, 0, 0, .3);
        color: #fff;
        font-size: 2em;
        text-align: center;
        cursor: pointer;
        -webkit-transform: rotate(0);
        -moz-transform: rotate(0);
        -ms-transform: rotate(0);
        transform: rotate(0)
    }

    #myhkplayer .switch-player .fa {
        -webkit-transition: 1s;
        -moz-transition: 1s;
        -ms-transition: 1s;
        transition: 1s;
        top: 50%
    }

    #myhkplayer .switch-player:hover {
        background-color: rgba(0, 0, 0, .1);
        color: #12A9EB
    }

    #myhkplayer.show {
        -webkit-transform: translate3d(300px, 0, 0);
        -moz-transform: translate3d(300px, 0, 0);
        -ms-transform: translate3d(300px, 0, 0);
        transform: translate3d(300px, 0, 0)
    }

    #myhkplayer.show .switch-player {
        background-color: rgba(0, 0, 0, .1);
        color: #12A9EB;
        opacity: 1
    }

    #myhkplayer.show .switch-player:hover {
        background-color: rgba(0, 255, 0, .6);
        color: #fff
    }

    #myhkplayer.show .switch-player .fa {
        -webkit-transform: rotate(180deg);
        -moz-transform: rotate(180deg);
        -ms-transform: rotate(180deg);
        transform: rotate(180deg)
    }

    #myhkplayer.showAlbumList .switch-playlist {
        color: #81c300
    }

    #myhkplayer.playing .player .control .status b {
        border-width: 0;
        top: 2px;
        left: 2px;
        z-index: 2000
    }

    #myhkplayer.playing .player .control .status b .play {
        margin-top: -40px
    }

    #myhkplayer.playing .flat {
        opacity: 1;
        -webkit-animation: rotate 18s linear infinite;
        -moz-animation: rotate 18s linear infinite;
        -ms-animation: rotate 18s linear infinite;
        animation: rotate 18s linear infinite
    }

    #myhkplayer.playing .flat i {
        position: absolute;
        top: 0;
        left: 0;
        opacity: 0;
        -webkit-animation: flat 4s cubic-bezier(.09, .64, .16, .94) infinite;
        -moz-animation: flat 4s cubic-bezier(.09, .64, .16, .94) infinite;
        -ms-animation: flat 4s cubic-bezier(.09, .64, .16, .94) infinite;
        animation: flat 4s cubic-bezier(.09, .64, .16, .94) infinite
    }

    #myhkplayer.playing .flat i:nth-of-type(1) {
        -webkit-transform: rotate(35deg) translateX(-5px);
        -moz-transform: rotate(35deg) translateX(-5px);
        -ms-transform: rotate(35deg) translateX(-5px);
        transform: rotate(35deg) translateX(-5px)
    }

    #myhkplayer.playing .flat i:nth-of-type(2) {
        -webkit-animation-delay: .8s;
        -moz-animation-delay: .8s;
        -ms-animation-delay: .8s;
        animation-delay: .8s;
        -webkit-transform: rotate(-5deg) translateX(-15px);
        -moz-transform: rotate(-5deg) translateX(-15px);
        -ms-transform: rotate(-5deg) translateX(-15px);
        transform: rotate(-5deg) translateX(-15px)
    }

    #myhkplayer.playing .flat i:nth-of-type(3) {
        -webkit-animation-delay: 1.6s;
        -moz-animation-delay: 1.6s;
        -ms-animation-delay: 1.6s;
        animation-delay: 1.6s;
        -webkit-transform: rotate(45deg) translateX(5px);
        -moz-transform: rotate(45deg) translateX(5px);
        -ms-transform: rotate(45deg) translateX(5px);
        transform: rotate(45deg) translateX(5px)
    }

    #myhkplayer.playing .flat i:nth-of-type(4) {
        -webkit-animation-delay: 2.8s;
        -moz-animation-delay: 2.8s;
        -ms-animation-delay: 2.8s;
        animation-delay: 2.8s;
        -webkit-transform: rotate(-25deg) translateX(-8px);
        -moz-transform: rotate(-25deg) translateX(-8px);
        -ms-transform: rotate(-25deg) translateX(-8px);
        transform: rotate(-25deg) translateX(-8px)
    }

    #myhkplayer.playing .flat i:nth-of-type(5) {
        -webkit-animation-delay: 3.2s;
        -moz-animation-delay: 3.2s;
        -ms-animation-delay: 3.2s;
        animation-delay: 3.2s;
        -webkit-transform: rotate(-35deg);
        -moz-transform: rotate(-35deg);
        -ms-transform: rotate(-35deg);
        transform: rotate(-35deg)
    }

    #myhkplayer.playing .flat i:nth-of-type(6) {
        -webkit-animation-delay: 4.5s;
        -moz-animation-delay: 4.5s;
        -ms-animation-delay: 4.5s;
        animation-delay: 4.5s;
        -webkit-transform: rotate(15deg) translateX(-5px);
        -moz-transform: rotate(15deg) translateX(-5px);
        -ms-transform: rotate(15deg) translateX(-5px);
        transform: rotate(15deg) translateX(-5px)
    }

    @-webkit-keyframes rotate {
        50% {
            -webkit-transform: rotate(-20deg);
            -moz-transform: rotate(-20deg);
            -ms-transform: rotate(-20deg);
            transform: rotate(-20deg)
        }
    }

    @-moz-keyframes rotate {
        50% {
            -webkit-transform: rotate(-20deg);
            -moz-transform: rotate(-20deg);
            -ms-transform: rotate(-20deg);
            transform: rotate(-20deg)
        }
    }

    @-ms-keyframes rotate {
        50% {
            -webkit-transform: rotate(-20deg);
            -moz-transform: rotate(-20deg);
            -ms-transform: rotate(-20deg);
            transform: rotate(-20deg)
        }
    }

    @keyframes rotate {
        50% {
            -webkit-transform: rotate(-20deg);
            -moz-transform: rotate(-20deg);
            -ms-transform: rotate(-20deg);
            transform: rotate(-20deg)
        }
    }

    @-webkit-keyframes flat {
        from {
            opacity: 1
        }
        to {
            top: -48px;
            left: 78px;
            opacity: 0;
            font-size: .6em
        }
    }

    @-moz-keyframes flat {
        from {
            opacity: 1
        }
        to {
            top: -48px;
            left: 78px;
            opacity: 0;
            font-size: .6em
        }
    }

    @-ms-keyframes flat {
        from {
            opacity: 1
        }
        to {
            top: -48px;
            left: 78px;
            opacity: 0;
            font-size: .6em
        }
    }

    @keyframes flat {
        from {
            opacity: 1
        }
        to {
            top: -48px;
            left: 78px;
            opacity: 0;
            font-size: .6em
        }
    }

    #myhkLrc ul {
        -webkit-transition: .5s;
        -moz-transition: .5s;
        -ms-transition: .5s;
        transition: .5s
    }

    #myhkLrc li {
        opacity: .5;
        -webkit-transition: .5s;
        -moz-transition: .5s;
        -ms-transition: .5s;
        transition: .5s;
        text-align: center;
        height: 40px;
        line-height: 40px;
        font-weight: 700;
        font-size: 24px;
        text-shadow: 1px 1px 1px #000;
        -webkit-transform: scale(.8);
        -moz-transform: scale(.8);
        -ms-transform: scale(.8);
        transform: scale(.8)
    }
    #myhkKsc .line span {
        margin-right: 5px;
        display: inline-block;
        white-space: nowrap;
        word-break: keep-all;
        word-break: break-word;
        word-wrap: break-word;
        -moz-hyphens: auto;
        hyphens: auto
    }

    #myhkKsc .line span.blank {
        width: 40px
    }

    #myhkKsc .line span em {
        font-weight: 700;
        font-style: normal;
        display: block;
        text-align: left
    }

    #myhkKsc .line .bg {
        bottom: 0;
        left: 0;
        color: #fff;
        background-color: unset
    }

    #myhkKsc .line .lighter {
        position: absolute;
        bottom: 0;
        left: 0;
        height: 40px;
        line-height: 40px;
        width: 100%;
        overflow: hidden
    }

    #myhkKsc .line .lighter em {
        width: 0;
        overflow: hidden
    }

    @keyframes myhk {
        100% {
            -webkit-transform: rotate(360deg);
            transform: rotate(360deg)
        }
    }

    @-webkit-keyframes myhk {
        100% {
            -webkit-transform: rotate(360deg)
        }
    }

    #myhkplayer .status {
        opacity: .3;
        -webkit-transition: all 1s ease 0s;
        -moz-transition: all 1s ease 0s;
        -o-transition: all 1s ease 0s;
        transition: all 1s ease 0s
    }

    #myhkplayer:hover .status {
        opacity: .8
    }

    #myhkplayer .blur-img {
        width: 300px;
        height: 155px;
        position: absolute
    }

    #myhkplayer .player .blur {
        display: none;
        position: relative;
        width: 300px;
        height: 155px;
        -webkit-filter: blur(20px);
        -moz-filter: blur(20px);
        -ms-filter: blur(20px);
        filter: blur(20px)
    }

    #myhkplayer .player .musicbottom .volumecontrol {
        position: absolute;
        -webkit-transition: .5s;
        -moz-transition: .5s;
        -ms-transition: .5s;
        transition: .5s;
        left: 5px;
        font-size: 1.2em;
        z-index: 999999;
        cursor: pointer
    }

    #myhkplayer .player .musicbottom .volumeprogress {
        -webkit-box-sizing: border-box;
        -moz-box-sizing: border-box;
        -webkit-box-sizing: border-box;
        display: none;
        position: absolute;
        top: -120px;
        left: -3px;
        width: 26px;
        background: #f1f2f2;
        border-radius: 4px;
        cursor: pointer;
        height: 120px;
        padding: 10px 0 10px 0;
        -webkit-transition: .1s;
        -moz-transition: 1s;
        -ms-transition: 1s;
        transition: 1s;
        -webkit-animation-name: fadeOut
    }

    #myhkplayer .player .musicbottom .volumecontrol:hover .volumeprogress, #myhkplayer .player .musicbottom .volumeprogress:hover {
        display: block;
        -webkit-animation-name: fadeIn;
        -webkit-animation-iteration-count: 1;
        -webkit-animation-delay: 0s
    }

    #myhkplayer .player .musicbottom .volumeprogress .progressbg {
        cursor: pointer;
        width: 6px;
        height: 100px;
        margin: 0 auto;
        background-color: #b0aeb2;
        position: relative
    }

    #myhkplayer .player .musicbottom .volumeprogress .progressbg .progressbg1 {
        width: 6px;
        height: 100%;
        margin: 0 auto;
        background-color: #12A9EB;
        position: absolute;
        bottom: 0
    }

    #myhkplayer .player .musicbottom .volumeprogress .ts {
        width: 16px;
        height: 16px;
        position: relative;
        top: 0;
        left: -5px;
        background-color: #fff;
        border-radius: 50%;
        box-shadow: 0 0 1px 1px rgba(0, 0, 0, .2);
        cursor: pointer;
        z-index: 2
    }

    #myhkplayer .player .musicbottom .playprogress {
        cursor: pointer;
        float: left;
        margin-left: 60px;
        width: calc(100% - 120px)
    }

    #myhkplayer .player .musicbottom .playprogress .progressbg {
        position: relative;
        width: 100%;
        top: 10.5px;
        height: 5px;
        background-color: #b0aeb2
    }

    #myhkplayer .player .musicbottom .playprogress .progressbg .progressbg1 {
        position: absolute;
        height: 5px;
        left: 0;
        top: 0;
        width: 0;
        background-color: #12A9EB;
        z-index: 2
    }

    #myhkplayer .player .musicbottom .playprogress .progressbg .progressbg2 {
        position: absolute;
        height: 5px;
        left: 0;
        top: 0;
        width: 0;
        background-color: #818680;
        z-index: 1
    }

    #myhkplayer .player .musicbottom .playprogress .progressbg .ts {
        width: 16px;
        height: 16px;
        position: absolute;
        top: -5.5px;
        left: 0;
        background-color: #fff;
        border-radius: 50%;
        box-shadow: 0 0 1px 1px rgba(0, 0, 0, .2);
        z-index: 2;
        margin-left: -8px
    }

    @media screen and (max-width: 680px) {

        #myhkKsc .line span {
            margin-right: 2px
        }

        #myhkKsc .line {
            position: absolute;
            font-size: .8em;
            -webkit-transition: .5s;
            -moz-transition: .5s;
            -ms-transition: .5s;
            transition: .5s;
            opacity: 0;
            -webkit-transform: scale(1.01);
            -moz-transform: scale(1.01);
            -ms-transform: scale(1.01);
            transform: scale(1.01);
            height: 20px;
            line-height: 20px
        }

        #myhkKsc .line .lighter {
            position: absolute;
            bottom: 0;
            left: 0;
            height: 20px;
            line-height: 20px;
            width: 100%;
            overflow: hidden
        }
        #myhkLrc li {
            -webkit-transition: .5s;
            -moz-transition: .5s;
            -ms-transition: .5s;
            transition: .5s;
            text-align: center;
            height: 20px;
            line-height: 20px;
            font-weight: 700;
            font-size: 1em;
            -webkit-transform: scale(.8);
            -moz-transform: scale(.8);
            -ms-transform: scale(.8);
            transform: scale(.8)
        }
    }

    @-webkit-keyframes top-left {
        0% {
            left: 0;
            top: 0
        }
        25% {
            left: 50px;
            top: 0;
            -webkit-transform: rotate(90deg)
        }
        50% {
            left: 50px;
            top: 50px;
            -webkit-transform: rotate(180deg)
        }
        75% {
            left: 0;
            top: 50px;
            -webkit-transform: rotate(270deg)
        }
        100% {
            left: 0;
            top: 0;
            -webkit-transform: rotate(360deg)
        }
    }

    @-webkit-keyframes top-right {
        0% {
            left: 50px;
            top: 0
        }
        25% {
            left: 50px;
            top: 50px
        }
        50% {
            left: 0;
            top: 50px
        }
        75% {
            left: 0;
            top: 0
        }
        100% {
            left: 50px;
            top: 0
        }
    }

    @-webkit-keyframes bottom-left {
        0% {
            left: 50px;
            top: 50px
        }
        25% {
            left: 0;
            top: 50px;
            -webkit-transform: rotate(90deg)
        }
        50% {
            left: 0;
            top: 0;
            -webkit-transform: rotate(180deg)
        }
        75% {
            left: 50px;
            top: 0;
            -webkit-transform: rotate(270deg)
        }
        100% {
            left: 50px;
            top: 50px;
            -webkit-transform: rotate(360deg)
        }
    }

    @-webkit-keyframes bottom-right {
        0% {
            left: 0;
            top: 50px
        }
        25% {
            left: 0;
            top: 0
        }
        50% {
            left: 50px;
            top: 0
        }
        75% {
            left: 50px;
            top: 50px
        }
        100% {
            left: 0;
            top: 50px
        }
    }

    @-moz-keyframes spinPulse {
        0% {
            box-shadow: 0 0 1px #505050;
            opacity: 0;
            -moz-transform: rotate(160deg)
        }
        50% {
            opacity: 1;
            -moz-transform: rotate(145deg)
        }
        100% {
            opacity: 0;
            -moz-transform: rotate(-320deg)
        }
    }

    @-moz-keyframes spinoffPulse {
        0% {
            -moz-transform: rotate(0)
        }
        100% {
            -moz-transform: rotate(360deg)
        }
    }

    @-webkit-keyframes spinPulse {
        0% {
            box-shadow: 0 0 1px #505050;
            opacity: 0;
            -webkit-transform: rotate(160deg)
        }
        50% {
            opacity: 1;
            -webkit-transform: rotate(145deg)
        }
        100% {
            opacity: 0;
            -webkit-transform: rotate(-320deg)
        }
    }

    @-webkit-keyframes spinoffPulse {
        0% {
            -webkit-transform: rotate(0)
        }
        100% {
            -webkit-transform: rotate(360deg)
        }
    }

    @-o-keyframes spinPulse {
        0% {
            box-shadow: 0 0 1px #505050;
            opacity: 0;
            -o-transform: rotate(160deg)
        }
        50% {
            opacity: 1;
            -o-transform: rotate(145deg)
        }
        100% {
            opacity: 0;
            -o-transform: rotate(-320deg)
        }
    }

    @-o-keyframes spinoffPulse {
        0% {
            -o-transform: rotate(0)
        }
        100% {
            -o-transform: rotate(360deg)
        }
    }

    @-ms-keyframes spinPulse {
        0% {
            box-shadow: 0 0 1px #505050;
            opacity: 0;
            -ms-transform: rotate(160deg)
        }
        50% {
            opacity: 1;
            -ms-transform: rotate(145deg)
        }
        100% {
            opacity: 0;
            -ms-transform: rotate(-320deg)
        }
    }

    @-ms-keyframes spinoffPulse {
        0% {
            -ms-transform: rotate(0)
        }
        100% {
            -ms-transform: rotate(360deg)
        }
    }

    #myhkplayer * {
        box-sizing: content-box;
        color: unset
    }

    [class*=" fa-"]:before, [class^=fa-]:before {
        margin: unset !important
    }

    #pdyf1 {
        animation: myhkmove 2s linear 0s infinite normal
    }

    @keyframes myhkmove {
        0% {
            left: 15px;
            top: -15px;
            opacity: 0
        }
        50% {
            opacity: 1;
            transform: rotate(9deg)
        }
        100% {
            left: 200px;
            top: -90px;
            opacity: 0
        }
    }

    #pdyf2 {
        animation: myhkmove2 2s linear .4s infinite normal
    }

    @keyframes myhkmove2 {
        0% {
            left: 15px;
            top: -15px;
            opacity: 0
        }
        50% {
            opacity: 1;
            transform: rotate(-9deg)
        }
        100% {
            left: 200px;
            top: -80px;
            opacity: 0
        }
    }

    #pdyf3 {
        animation: myhkmove3 2s linear .7s infinite normal
    }

    @keyframes myhkmove3 {
        0% {
            left: 15px;
            top: -15px;
            opacity: 0
        }
        50% {
            opacity: 1;
            transform: rotate(9deg)
        }
        100% {
            left: 200px;
            top: -70px;
            opacity: 0
        }
    }
    #myhkplaye .infos i, #myhkplaye .status .note i {
        color: inherit
    }
</style>