import request from "@/utils/request"

export function getList(params) {
    return request({
        url:"/api/player/music",
        method:"get",
        params
    })
}
export function getDetail(id) {
    return request({
        url:"/api/player/music/"+id,
        method:"get"
    })
}