import {getToken,getCatch} from "@/utils"
import state from "@/store/state";

export default {
    isLogin (state) {
        var isLogin = state.isLogin || getToken()?true:false
        return isLogin
    },
    userInfo(state){
        var userInfo =state.userInfo || getCatch('yuan-jie-user')
        return userInfo
    },
    isOpenLoginDialog (state) {
        return state.isOpenLoginDialog
    },
	isOpenMobileProp(state){
		return state.isOpenMobileProp
	},
    isOpenMusicPlayer(state){
        return state.isOpenMusicPlayer
    },
    footerIcpBei(state){
        return state.footerIcpBei
    },
    footerBusinessBei(state){
        return state.footerBusinessBei
    },
    tokenKey(state){
        return state.tokenKey
    },
    musicPlayer(){
        return state.musicPlayer
    }
}