<template>

    <div class="sign">
        <div class="sign-mask"></div>
        <div class="containers">
            <div class="sign-social">
                <h2>{{formType|formTypeFilter}}</h2>
                <div class="close-icon" @click.stop="closeLoginDialog">
                    <i class="icon fa fa-close"></i>
                </div>
            </div>
            <div class="sign-form">
                <form v-if="formType == 'login'" @submit.prevent action="javascript:;" id="sign-in">
                    <div class="form-item">
                        <input v-model="loginForm.username" type="text" name="edtUserName" class="form-control"
                               placeholder="账号" required="required"/>
                        <i class="fa fa-user"></i></div>
                    <div class="form-item">
                        <input v-model="loginForm.pwd" type="password" class="form-control" placeholder="密码"
                               required="required"/>
                        <i class="fa fa-lock" style="font-size:18px;"></i>
                    </div>
                    <div class="sign-submit">
                        <label @click.stop="toRegister">注册</label>
                        <label @click.stop="toForget">重置密码</label>
                        <el-button type="primary" :loading="loginLoading" @click="login">登录</el-button>
                    </div>
                    <div id="qqLogin" class="third-auth">
                        <a @click.stop="wxLogin" title="微信系小程序登录" class="wx-login-btn" href="javascript:;"></a>
                        <a @click.stop="qqLogin" title="QQ登录" class="qq-login-btn" href="javascript:;"></a>
                        <a @click.stop="hbLogin" title="HBuilder登录" class="hb-login-btn" href="javascript:;"></a>
                    </div>
                    <div class="wx-btn" @click="wxWebLogin">
                        公众号登录
                    </div>
                </form>
                <form v-if="formType == 'bind_account'" @submit.prevent action="javascript:;">
                    <div class="form-item">
                        <input v-model="bindPhoneForm.username" name="edtUserName" class="form-control"
                               placeholder="手机号或邮箱" required="required"/>
                        <i class="fa fa-user"></i>
                    </div>
                    <div class="form-item">
                        <input v-model="bindPhoneForm.code" type="number" class="form-control vertical-code"
                               placeholder="短信验证码" required="required"/>
                        <i class="fa fa-lock" style="font-size:18px;"></i>
                        <input @click="sendBindCodeFun" type="button" v-loading="sendLoading" v-model="btnText"
                               class="vertical-code-btn" autocomplete="off"/>
                    </div>
                    <div class="sign-submit bind-btn">
                        <el-button :loading="bindLoading" @click="bingPhone">绑定</el-button>
                    </div>
                </form>
                <form v-if="formType == 'register'" @submit.prevent action="javascript:;">
                    <div class="form-item">
                        <input v-model="registerForm.username" name="edtUserName" class="form-control"
                               placeholder="手机号或邮箱" required="required"/>
                        <i class="fa fa-user"></i>
                    </div>
                    <div class="form-item">
                        <input v-model="registerForm.code" name="code" type="number" class="form-control vertical-code"
                               placeholder="短信验证码" required="required"/>
                        <i class="fa fa-shield" style="font-size:18px;"></i>
                        <el-button type="primary" :loading="sendLoading" @click="doSendRegisterCode">{{btnText}}</el-button>
                    </div>
                    <div class="form-item">
                        <input v-model="registerForm.password" name="password" type="password" class="form-control"
                               placeholder="登录密码" required="required"/>
                        <i class="fa fa-lock" style="font-size:18px;"></i>
                    </div>
                    <div class="form-item">
                        <input v-model="registerForm.pwd" name="pwd" type="password" class="form-control"
                               placeholder="确认密码" required="required"/>
                        <i class="fa fa-lock" style="font-size:18px;"></i>
                    </div>
                    <div class="sign-submit bind-btn">
                        <label @click.stop="formType = 'login'">返回登录</label>
                        <el-button type="primary" :loading="loginLoading" @click="doRegister">注册</el-button>
                    </div>
                </form>
                <form v-if="formType == 'forget'" @submit.prevent action="javascript:;">
                    <div class="form-item">
                        <input v-model="forgetForm.username" name="edtUserName" class="form-control"
                               placeholder="手机号或邮箱" required="required"/>
                        <i class="fa fa-user"></i>
                    </div>
                    <div class="form-item">
                        <input v-model="forgetForm.code" name="code" type="number" class="form-control vertical-code"
                               placeholder="短信验证码" required="required"/>
                        <i class="fa fa-shield" style="font-size:18px;"></i>
                        <el-button type="primary" :loading="sendLoading" @click="doSendForgetCode">{{btnText}}</el-button>
                    </div>
                    <div class="form-item">
                        <input v-model="forgetForm.password" name="password" type="password" class="form-control"
                               placeholder="登录密码" required="required"/>
                        <i class="fa fa-lock" style="font-size:18px;"></i>
                    </div>
                    <div class="form-item">
                        <input v-model="forgetForm.pwd" name="pwd" type="password" class="form-control"
                               placeholder="确认密码" required="required"/>
                        <i class="fa fa-lock" style="font-size:18px;"></i>
                    </div>
                    <div class="sign-submit bind-btn">
                        <label @click.stop="formType = 'login'">返回登录</label>
                        <el-button type="primary" :loading="loginLoading" @click="doForget">重置</el-button>
                    </div>
                </form>
                <div v-if="formType == 'wx_mp_qrcode' || formType == 'wx_web_qrcode'" class="qr-code-login">
                    <div id="qrcode" class="qr-img" v-loading="loginLoading">
                        <canvas ref="qrcodeCanvas"></canvas>
                    </div>
                    <div class="wx-btn" @click="returnHomeLogin">
                        返回密码登录
                    </div>
                </div>
            </div>
        </div>
    </div>

</template>

<script>
    import QRCode from 'qrcode'
    import {mapMutations, mapGetters} from "vuex"
    import {setCatch, delCatch, showLoading, hideLoading, setToken, encrypt, stringUtil} from "@/utils"
    import {
        login,
        register,
        forgetByCode,
        bindPhoneByCode,
        thirdLogin,
        getWxImgCode,
        getWxUUID,
        getWxToken,
        thirdAuth,
        sendBindCode,
        sendRegisterCode,
        sendForgetCode,
        getWxMpCode,
        getWxWebCode,
        checkWxWebCode
    } from "@/api/auth"

    let timer;
    export default {
        name: "login",
        data() {
            return {
                loginForm: {
                    username: '',
                    pwd: '',
                    password: ''
                },
                bindPhoneForm: {
                    username: '',
                    code: '',
                    openId: '',
                    authType: ''
                },
                registerForm: {
                    username: '',
                    password: '',
                    code: '',
                    pwd: ''
                },
                forgetForm:{
                    username:'',
                    password:'',
                    code:''
                },
                bindLoading: false,
                sendLoading: false,
                loginLoading: false,
                formType: 'login',
                btnText: '获取验证码'
            }
        },
        filters: {
            formTypeFilter(val) {
                if (val == 'register') {
                    return '账户注册'
                }
                if (val == 'forget'){
                    return '重置密码'
                }
                if (val == 'login') {
                    return '账户登录'
                }
                if (val == 'bind_account') {
                    return '账户绑定'
                }
                if (val == 'wx_mp_qrcode' || val == 'wx_web_qrcode') {
                    return '微信扫码'
                }
            }
        },
        computed: {
            ...mapGetters(['isOpenLoginDialog'])
        },
        watch: {
            isOpenLoginDialog(newVal) {
                if (newVal) {
                    let bindAccount = window.localStorage.getItem("bind-account")
                    if (bindAccount) {
                        bindAccount = JSON.parse(bindAccount)
                        this.formType = bindAccount.eventType
                        this.bindPhoneForm.authType = bindAccount.authType
                        this.bindPhoneForm.openId = bindAccount.authId
                    }
                }
            }
        },
        methods: {
            ...mapMutations(['setLoginState', 'setOpenLoginDialogState', 'setUserInfo', 'setOpenMusicPlayer']),
            toRegister() {
                this.formType = 'register'
            },
            toForget(){
                this.formType = 'forget'
            },
            returnHomeLogin() {
                this.formType = 'login'
            },
            closeLoginDialog() {
                this.setOpenLoginDialogState(false)
                this.setOpenMusicPlayer(true)
            },
            login() {
                let _this = this
                if (stringUtil.isEmpty(this.loginForm.username)) {
                    this.$message.error("账号不能为空")
                    return
                }
                if (stringUtil.isEmpty(this.loginForm.pwd)) {
                    this.$message.error("密码不能为空")
                    return
                }
                _this.loginLoading = true
                _this.msg = ''
                _this.loginForm.password = encrypt(_this.loginForm.pwd)
                login(_this.loginForm).then(res => {
                    _this.loginLoading = false
                    _this.loginForm.pwd = ''
                    setToken(res.token)
                    setCatch('yuan-jie-user', res.user)
                    _this.setUserInfo(res.user)
                    _this.setLoginState(true)
                    _this.setOpenLoginDialogState(false)
                }).catch(err => {
                    _this.loginLoading = false
                })
            },
            bindLogin(params) {
                var _this = this
                setToken(params.token)
                setCatch('yuan-jie-user', params.user)
                _this.setLoginState(true)
                _this.setOpenLoginDialogState(false)
                hideLoading()
                if (params.authType == 1 || params.authType == 12) {
                    delCatch('third-login-page')
                }
            },
            bingPhone() {
                var _this = this
                _this.bindLoading = true
                bindPhoneByCode(_this.bindPhoneForm).then(function (ret) {
                    _this.bindLoading = false
                    if (ret.status == 200) {
                        _this.thirdLoginFun({
                            authId: _this.bindPhoneForm.openId,
                            authType: _this.bindPhoneForm.authType
                        })
                    } else {
                        _this.$message.error(ret.message)
                    }
                }, function (err) {
                    _this.bindLoading = false
                })
            },
            doRegister() {
                if (stringUtil.isEmpty(this.registerForm.username)) {
                    this.$message.error("账号不能为空")
                    return
                }
                if (stringUtil.isEmpty(this.registerForm.code)) {
                    this.$message.error("验证码不能为空")
                    return
                }
                if (stringUtil.isEmpty(this.registerForm.password)) {
                    this.$message.error("密码不能为空")
                    return
                }
                if (this.registerForm.password != this.registerForm.pwd) {
                    this.$message.error("两次密码输入不一致")
                    return
                }
                let reg =/^(?=.*[A-Za-z])(?=.*\d)[A-Za-z\d]{6,}$/
                if (!this.registerForm.password.match(reg)) {
                    this.$message.error("密码至少6位且数字与字母组合")
                    return
                }
                let registerData = JSON.parse(JSON.stringify(this.registerForm))
                registerData["password"] = encrypt(this.registerForm.password)
                this.registerForm = {
                    username: '',
                    password: '',
                    code: '',
                    pwd: ''
                }
                this.loginLoading = true
                register(registerData).then(res => {
                    if (res.status == 200) {
                        login(registerData).then(ret => {
                            setToken(ret.token)
                            this.setUserInfo(ret.user)
                            this.setLoginState(true)
                            this.setOpenLoginDialogState(false)
                            this.loginLoading = false
                        }).catch(err => {
                            this.loginLoading = false
                        })
                    }else {
                        this.loginLoading = false
                    }
                }).catch(err => {
                    this.loginLoading = false
                })
            },
            doForget(){
                if (stringUtil.isEmpty(this.forgetForm.username)) {
                    this.$message.error("账号不能为空")
                    return
                }
                if (stringUtil.isEmpty(this.forgetForm.code)) {
                    this.$message.error("验证码不能为空")
                    return
                }
                if (stringUtil.isEmpty(this.forgetForm.password)) {
                    this.$message.error("密码不能为空")
                    return
                }
                if (this.forgetForm.password != this.forgetForm.pwd) {
                    this.$message.error("两次密码输入不一致")
                    return
                }
                let reg =/^(?=.*[A-Za-z])(?=.*\d)[A-Za-z\d]{6,}$/
                if (!this.forgetForm.password.match(reg)) {
                    this.$message.error("密码至少6位且数字与字母组合")
                    return
                }
                let forgetData = JSON.parse(JSON.stringify(this.forgetForm))
                forgetData["password"] = encrypt(this.forgetForm.password)
                this.forgetForm = {
                    username:'',
                    password:'',
                    code:'',
                    pwd:''
                }
                this.loginLoading = true
                forgetByCode(forgetData).then(res=>{
                    if (res.status == 200){
                        login(forgetData).then(ret => {
                            setToken(ret.token)
                            this.setUserInfo(ret.user)
                            this.setLoginState(true)
                            this.setOpenLoginDialogState(false)
                            this.loginLoading = false
                        }).catch(err => {
                            this.loginLoading = false
                        })
                    } else {
                        this.loginLoading = false
                    }
                }).catch(()=>{
                    this.loginLoading = false
                })
            },
            qqLogin() {
                setCatch('third-login-page', this.$route.name)
                QC.Login.showPopup({
                    appId: '101546073',
                    redirectURI: 'https://yuanjkeji.com/qq_login'
                })
            },
            wxLogin() {
                this.formType = 'wx_mp_qrcode'
                this.loginLoading = true
                let loginCode = localStorage.getItem("wx_login_code")
                if (!loginCode) {
                    loginCode = Date.now()
                    localStorage.setItem("wx_login_code",loginCode)
                }
                getWxMpCode({
                    loginCode:loginCode
                }).then(res=>{
                    if (res.status == 200){
                        this.$nextTick(()=>{
                            const canvas = this.$refs.qrcodeCanvas
                            QRCode.toCanvas(canvas, res.data.url_link,{width:200,height:200}, error => {
                                if (!error){
                                    this.loginLoading = false
                                }
                            })
                        })
                        this.checkWxMpLogin(loginCode)
                    }else {
                        this.loginLoading = false
                    }
                }).catch(()=>{
                    this.loginLoading = false
                })
            },
            checkWxMpLogin(loginCode){
                getWxMpCode({
                    loginCode:loginCode
                }).then(res=>{
                    if (res.status == 200){
                        if (res.data['authId']) {
                            localStorage.removeItem("wx_login_code")
                            this.thirdLoginFun({
                                authId:res.data.authId,
                                authType:res.data.authType
                            })
                        } else {
                            if (this.formType.indexOf('qrcode') > -1){
                                setTimeout(()=>{
                                        this.checkWxMpLogin(loginCode)
                                 },2000)
                            }else {
                                localStorage.removeItem("wx_login_code")
                            }
                        }
                    }
                })
            },
            wxWebLogin(){
                let loginCode = localStorage.getItem("wx_login_code")
                if (!loginCode) {
                    loginCode = Date.now()
                    localStorage.setItem("wx_login_code",loginCode)
                }
                this.formType = 'wx_web_qrcode'
                this.loginLoading = true
                getWxWebCode({
                    code:loginCode
                }).then(res=>{
                    if (res.status == 200){
                        this.$nextTick(()=>{
                            const canvas = this.$refs.qrcodeCanvas
                            QRCode.toCanvas(canvas, res.data.url,{width:200,height:200}, error => {
                                if (!error){
                                    this.loginLoading = false
                                }
                            })
                        })
                        this.checkWxWebCode(loginCode)
                    }else{
                        this.loginLoading = false
                    }
                }).catch(()=>{
                    this.loginLoading = false
                })
            },
            checkWxWebCode(loginCode){
                 checkWxWebCode({
                     code:loginCode
                 }).then(res=>{
                     if (res.status == 200){
                         if (res.data.eventType == 'login'){
                             window.localStorage.removeItem("wx_login_code")
                             this.thirdLoginFun({
                                 authId:res.data.authId,
                                 authType:res.data.authType
                             })
                         }else if(res.data.eventType == 'bind_account'){
                             window.localStorage.removeItem("wx_login_code")
                             this.bindPhoneForm.authType = res.data.authType
                             this.bindPhoneForm.openId = res.data.authId
                             window.localStorage.setItem("bind-account",JSON.stringify({
                                 authId:res.data.authId,
                                 authType:res.data.authType,
                                 eventType:'bind_account'
                             }))
                             this.formType = 'bind_account'
                         } else {
                             if (this.formType.indexOf('qrcode') > -1){
                                 setTimeout(()=>{
                                     this.checkWxWebCode(loginCode)
                                 },2000)
                             }else {
                                 localStorage.removeItem("wx_login_code")
                             }
                         }
                     }
                 })
            },
            thirdLoginFun(obj){
                thirdLogin({
                    authId:obj.authId,
                    authType:obj.authType
                }).then(res=>{
                    if (res.status == 200){
                        setToken(res.token)
                        setCatch('yuan-jie-user', res.user)
                        this.setLoginState(true)
                        this.setOpenLoginDialogState(false)
                        this.formType = 'login'
                        window.localStorage.removeItem("bind-account")
                        window.localStorage.removeItem("wx_login_code")
                    }
                })
            },
            hbLogin() {
                let clientId = "WVFTyJgys9"
                let winName = window.name
                let redirectUri = encodeURIComponent("https://yuanjkeji.com/ios-upload/hb-redirect/" + winName)
                let requestUrl = "https://account.dcloud.net.cn/oauth2/webAuthorize?client_id=" + clientId + "&redirect_uri=" + redirectUri + "&response_type=code"
                window.open(requestUrl)
            },
            sendBindCodeFun() {
                let _this = this
                if (stringUtil.isEmpty(this.bindPhoneForm.username)) {
                    _this.$message.error("请输入账号")
                    return
                }
                if (!stringUtil.isEmail(this.bindPhoneForm.username) && !stringUtil.isPhone(this.bindPhoneForm.username)) {
                    _this.$message.error("账号格式错误")
                    return
                }
                _this.sendLoading = true
                sendBindCode({
                    account: this.bindPhoneForm.username,
                    authType: this.bindPhoneForm.authType
                }).then(function (res) {
                    _this.sendLoading = false
                    if (res.status == 200) {
                        var timeNum = 60
                        timer = setInterval(function () {
                            if (timeNum == 0) {
                                timeNum = 60
                                _this.btnText = '获取验证码'
                                clearInterval(timer)
                            } else {
                                timeNum--
                                _this.btnText = timeNum + '秒'
                            }
                        }, 1000)
                        _this.$message.success(res.message)
                    } else {
                        _this.$message.error(res.message)
                    }

                }, function (err) {
                    _this.sendLoading = false
                })
            },
            doSendRegisterCode() {
                let _this = this
                if (stringUtil.isEmpty(this.registerForm.username)) {
                    this.$message.error("账户不能为空")
                    return
                }
                if (!stringUtil.isPhone(this.registerForm.username) && !stringUtil.isEmail(this.registerForm.username)) {
                    this.$message.error("请输入邮箱或手机号")
                    return
                }
                _this.sendLoading = true
                sendRegisterCode({
                    account: this.registerForm.username,
                }).then(function (res) {
                    _this.sendLoading = false
                    if (res.status == 200) {
                        var timeNum = 60
                        timer = setInterval(function () {
                            if (timeNum == 0) {
                                timeNum = 60
                                _this.btnText = '获取验证码'
                                clearInterval(timer)
                            } else {
                                timeNum--
                                _this.btnText = timeNum + '秒'
                            }
                        }, 1000)
                        _this.$message.success(res.message)
                    } else {
                        _this.$message.error(res.message)
                    }

                }, function (err) {
                    _this.sendLoading = false
                })
            },
            doSendForgetCode(){
                let _this = this
                if (stringUtil.isEmpty(this.forgetForm.username)) {
                    this.$message.error("账户不能为空")
                    return
                }
                if (!stringUtil.isPhone(this.forgetForm.username) && !stringUtil.isEmail(this.forgetForm.username)) {
                    this.$message.error("请输入邮箱或手机号")
                    return
                }
                _this.sendLoading = true
                sendForgetCode({
                    account: this.forgetForm.username,
                }).then(function (res) {
                    _this.sendLoading = false
                    if (res.status == 200) {
                        var timeNum = 60
                        timer = setInterval(function () {
                            if (timeNum == 0) {
                                timeNum = 60
                                _this.btnText = '获取验证码'
                                clearInterval(timer)
                            } else {
                                timeNum--
                                _this.btnText = timeNum + '秒'
                            }
                        }, 1000)
                        _this.$message.success(res.message)
                    } else {
                        _this.$message.error(res.message)
                    }

                }, function (err) {
                    _this.sendLoading = false
                })
            },
            toLoginWin() {
                this.formType = 'login'
            }
        },
        created() {
            let bindAccount = window.localStorage.getItem("bind-account")
            if (bindAccount) {
                bindAccount = JSON.parse(bindAccount)
                this.formType = bindAccount.eventType
                this.bindPhoneForm.authType = bindAccount.authType
                this.bindPhoneForm.openId = bindAccount.authId
            }
        },
        mounted() {
            var _this = this
            window.addEventListener('message', function (e) {
                var params = e.data;
                if (params.eventType && params.eventType === 'login') {
                    _this.bindLogin(params)
                }
                if (params.eventType && params.eventType === 'bind_account') {
                    _this.formType = params.eventType
                    _this.bindPhoneForm.authType = params.authType
                    _this.bindPhoneForm.openId = params.authId
                    window.localStorage.setItem("bind-account", JSON.stringify({
                        eventType: params.eventType,
                        authId: params.authId,
                        authType: params.authType
                    }))
                    hideLoading()
                    if (params.authType == 1) {
                        delCatch('third-login-page')
                    }
                }
            });
        }
    }
</script>
<style scoped lang="less">
    .sign-show{
        height: 100%;
        width: 100%;
        overflow: hidden;
        .sign {
            opacity: 1.0;
            visibility: visible;
            .containers {
                -webkit-transform: rotateX(-0deg) translateZ(0) translateY(0%);
                -moz-transform: rotateX(-0deg) translateZ(0) translateY(0%);
                -ms-transform: rotateX(-0deg) translateZ(0) translateY(0%);
                -o-transform: rotateX(-0deg) translateZ(0) translateY(0%);
                transform: rotateX(-0deg) translateZ(0) translateY(0%)
            }
        }
    }
    .sign {
        position: fixed;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        z-index: 999;
        -webkit-perspective: 500px;
        -moz-perspective: 500px;
        -ms-perspective: 500px;
        -o-perspective: 500px;
        perspective: 500px;
        -webkit-transition: all 0.3s;
        -moz-transition: all 0.3s;
        -ms-transition: all 0.3s;
        -o-transition: all 0.3s;
        transition: all 0.3s;
        opacity: 0.0;
        visibility: hidden;
        overflow: hidden;

        form {
            padding: 0 30px
        }
        h4 {
            font-weight: 500;
            font-family: inherit;
            line-height: 1.1;
        }

        h2 {
            padding: 20px 0;
        }
        h3 {
            margin: 0 -30px 20px;
            background-color: #fff;
            border-top-left-radius: 4px;
            border-top-right-radius: 4px;
            padding: 25px 50px;
            font-size: 18px;
            color: #3da9f7;
            small {
                float: right;
                cursor: pointer;
                color: #bbb;
                font-size: 14px;
                margin-top: 4px
            }
        }
        h6 {
            margin-bottom: 15px;
            position: relative
        }
        .sign-mask {
            position: fixed;
            cursor: pointer;
            width: 100%;
            height: 100%;
            top: 0;
            left: 0;
            background-color: rgba(0, 0, 0, 0.1);
            opacity: 0.90;
            filter: alpha(opacity=80)
        }
        .containers {
            background-color: #ffffff;
            border-radius: 5px;
            min-width: 262px;
            max-width: 400px;
            position: absolute;
            height: fit-content;
            overflow: hidden;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            z-index: 9;
            margin: auto;
            -webkit-transition: all 0.3s;
            -moz-transition: all 0.3s;
            -ms-transition: all 0.3s;
            -o-transition: all 0.3s;
            transition: all 0.3s;
            -webkit-transform: rotateX(-20deg) translateZ(200px) translateY(20%);
            -moz-transform: rotateX(-20deg) translateZ(200px) translateY(20%);
            -ms-transform: rotateX(-20deg) translateZ(200px) translateY(20%);
            -o-transform: rotateX(-20deg) translateZ(200px) translateY(20%);
            transform: rotateX(-20deg) translateZ(200px) translateY(20%);
            .sign-social {
                border-bottom: 1px solid #eaeaea;
                margin-bottom: 20px;
                overflow: hidden;
                position: relative;
                h2 {
                    font-size: 20px;
                    font-weight: bold;
                    text-align: center;
                }
                .close-icon{
                    position: absolute;
                    right: 20px;
                    top: 20px;
                    width: 20px;
                    height: 20px;
                    cursor: pointer;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    .icon{
                        font-size: 16px;
                    }
                }
            }
            .sign-form{
                .qr-code-login{
                    width: 100%;
                    height: 260px;
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    .qr-img{
                        width: 200px;
                        height: 200px;
                    }
                }
                .wx-btn {
                    width: 100%;
                    height: 2rem;
                    line-height: 2rem;
                    text-align: center;
                    border-radius: 5px;
                    margin-bottom: 20px;
                    cursor: pointer;
                    font-size: 14px;
                    color: #007aff;
                }
                .form-item {
                    margin-bottom: 15px;
                    position: relative;
                    display: flex;
                    i {
                        position: absolute;
                        top: 12px;
                        left: 12px;
                        font-size: 16px
                    }
                }
                .form-control {
                    background: #e6eaed;
                    border: none;
                    outline: none;
                    min-height: 40px;
                    display: block;
                    width: 100%;
                    height: 34px;
                    padding: 6px 32px;
                    font-size: 14px;
                    line-height: 1.42857143;
                    color: #555;
                    background-color: #fff;
                    background-image: none;
                    border: 1px solid #ccc;
                    border-radius: 4px;
                    -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, .075);
                    box-shadow: inset 0 1px 1px rgba(0, 0, 0, .075);
                    -webkit-transition: border-color ease-in-out .15s, -webkit-box-shadow ease-in-out .15s;
                    -o-transition: border-color ease-in-out .15s, box-shadow ease-in-out .15s;
                    transition: border-color ease-in-out .15s, box-shadow ease-in-out .15s;
                }
                .sign-submit {
                    overflow: hidden;
                    text-align: left;
                    line-height: 0;
                    padding-bottom: 20px;
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                    .btn-primary {
                        color: #fff;
                        background-color: #737f99;
                        border-color: #2e6da4;
                    }
                    .btn {
                        min-width: 100px;
                        min-height: 40px;
                        display: inline-block;
                        padding: 6px 12px;
                        margin-bottom: 0;
                        font-size: 14px;
                        outline: none;
                        font-weight: normal;
                        line-height: 1.42857143;
                        text-align: center;
                        white-space: nowrap;
                        vertical-align: middle;
                        -ms-touch-action: manipulation;
                        touch-action: manipulation;
                        cursor: pointer;
                        -webkit-user-select: none;
                        -moz-user-select: none;
                        -ms-user-select: none;
                        user-select: none;
                        background-image: none;
                        border: 1px solid transparent;
                        border-radius: 4px;
                        -webkit-appearance: button;
                        -webkit-transition: all .2s ease-in;
                        -moz-transition: all .2s ease-in;
                        -o-transition: all .2s ease-in;
                        transition: all .2s ease-in;
                    }
                    .btn:hover {
                        color: #fff;
                        background-color: #3e3e3e;
                    }
                    label {
                        margin-top: 20px;
                        font-weight: normal;
                        display: inline-block;
                        max-width: 100%;
                        font-size: 14px;
                        color: #007aff;
                        cursor: pointer;
                        input {
                            position: relative;
                            top: 2px;
                            margin-right: 4px
                        }
                    }
                }
                /*第三方登录*/
                .third-auth {
                    text-align: center;
                    position: relative;
                    margin-bottom: 20px;
                    position: relative;
                }
                 .third-auth:after {
                     position: absolute;
                     content: "";
                     width: 100%;
                     height: 1px;
                     left: 0px;
                     top: -10px;
                     background: #cacaca;
                }

                .third-auth a {
                    color: #666;
                    margin: 0px 20px;
                    width: 40px;
                    height: 40px;
                    -webkit-box-sizing: border-box;
                    -moz-box-sizing: border-box;
                    box-sizing: border-box;
                    display: inline-block;
                    border-radius: 4px;
                    zoom: 1;
                    background-size: 100% 100%;

                }

                .wx-login-btn {
                    background: #fff url(../../assets/image/wx_login.png) no-repeat center center;
                }

                .qq-login-btn {
                    background: #fff url(../../assets/image/qq_login.png) no-repeat center center;
                }

                .hb-login-btn {
                    background: #fff url(../../assets/image/hb_login.png) no-repeat center center;
                }

                .third-auth a.dt {
                    background-position: right;
                }

                .third-auth a.wx {
                    background-position: center;
                }

                .third-auth a.qq {
                    background-position: 0 0;
                }

                /*注册页面*/
                .vertical-code {
                    float: left;
                    margin-right: 5px;
                    width: 70% !important;
                }

                .vertical-code-btn {
                    float: right;
                    width: auto;
                    position: absolute;
                    min-width: 100px;
                    min-height: 40px;
                    display: inline-block;
                    margin-bottom: 0;
                    right: 0px;
                    border: 1px solid #E0E0E0;
                    border-radius: 4px;
                    cursor: pointer;
                }

                .bind-btn {
                    margin-top: 20px;
                }
            }

        }
    }
</style>