import {setCatch,setToken} from "@/utils"

const mutations = {
    setLoginState(state,val =false){
        if (!val){
            setToken('')
        }
        state.isLogin = val
    },
    setOpenLoginDialogState(state,val=false){
        state.isOpenLoginDialog = val
    },
	setOpenMobilePropState(state,val=false){
		 state.isOpenMobileProp = val
	},
    setOpenMusicPlayer(state,val=true){
        state.isOpenMusicPlayer = val
    },
    setUserInfo(state,val = null){
        state.userInfo =val
        setCatch('yuan-jie-user',val)
    },
    setMusicPlayer(state,val=null){
        if (val.playData||val.playData === false){
            state.musicPlayer.playData = val.playData
        }
        if (val.startPlay || val.startPlay === false){
            state.musicPlayer.startPlay = val.startPlay
        }
        if (val.playIndex||val.playIndex === 0){
            state.musicPlayer.playIndex = val.playIndex
        }
        if (val.playState||val.playState === 0){
            state.musicPlayer.playState = val.playState
        }
        if (val.volume || val.volume === 0){
            state.musicPlayer.volume = val.volume
        }
    }
}
export default mutations