<template>
	<div class="top-header">
		<div :class="scrollPercent > 10?'top-bar fixed-nav fixed-enabled':'top-bar fixed-nav fixed-appear'">
			<div class="container secnav clearfix">
				<div class="fav-subnav">
					<div class="top-bar-left pull-left navlogo">
						<a href="" class="logo box">
							<img :src="require('@/assets/image/logo_pc.png')" class="logo-light" id="logo-light"><b class="shan"></b></a>
					</div>
					<div class="search-warp clearfix">
						<form name="search" method="post" @submit.prevent="doSearch" action="javascript:;">
							<div class="search-area">
								<input class="search-input" placeholder="搜索感兴趣的知识和文章" type="text" name="q">
								<!--搜索提示关键词-->
								<ul class="s-drop-down">
									<li v-for="d in 5">
										<span class="s-drop-n num1">1</span>
										<span class="s-drop-t">zblogPHP调用文章总数、置顶数、tag总数等相关代码教程</span>
										<span class="s-drop-v">3.8 K 阅读</span>
									</li>
								</ul>
							</div>
							<button class="showhide-search" type="submit"><i class="icon font-search"></i>搜索一下</button>
						</form>
					</div>
				</div>
			</div>
			<div class="new-header container clearfix">
				<!--logo置顶-->
				<div class="top-bar-left header-nav fl" data-type="index" data-infoid="index">
					<a @click.stop="headerClick(0)" href="javascript:;" class="header_logo">
						<img :src="require('@/assets/image/logo_pc.png')" /></a>
					<div class="nav-sjlogo" @click="openMobileProp">
						<i class="nav-bar"><span></span><span></span><span></span></i>
						<i class="icon font-search top-search"></i>
					</div>
					<form name="search" class="m_searchform" method="post" action="javascript:;">
						<input class="searchInput" type="text" name="q" size="11" placeholder="请输入搜索内容..." value=""
							id="ms">
						<input type="submit" class="btn_m" value="搜索">
					</form>
					<aside
						:class="isOpenMobileProp?'mobile_aside mobile_nav mobile_nav_on':'mobile_aside mobile_nav'">
						<div class="mobile_top_nav"
							:style="'background-image:url('+require('@/assets/image/m_nav_top_bg.jpg')+');'">
							<a v-if="isLogin" class="m_top_login"><img class="widget-about-image"
									:src="require('@/assets/image/header.jpg')" alt="游客默认头像" height="70"
									width="70"></a>
							<div v-if="!isLogin" class="m_top_name" @click="handleLogin()">
								<h3>游客,您好</h3><a
									href="javascript:;">请登录</a>
							</div>
							<div v-if="isLogin" class="m_top_name">
								<!--<h3>欢迎您<span class="autlv aut-0 vs" v-text="userInfo?userInfo.roles[0].name:'暂无角色'"></span></h3>-->
								<a
									href="javascript:;">{{userInfo.nickName}}</a>
							</div>
						</div>
						<ul class="top-bar-menu nav-pills">

							<li v-for="(d,index) in headerBar" @click.stop="headerClick(index)"
								:class="d.select === true?'active':''">
								<a>
									<i :class="'icon '+d.icon"></i>{{d.text}}
								</a>
							</li>

						</ul>
						<ul class="top-bar-menu nav-pills mobile_nav_user">

							<li v-if="isLogin" @click.stop="logout">
								<a>
									<i class="icon fa fa-sign-out"></i>退出登录
								</a>
							</li>
							<li v-if="!isLogin" @click.stop="handleLogin">
								<a>
									<i class="icon fa fa-sign-in"></i>点我登陆
								</a>
							</li>

						</ul>
					</aside>
					<div id="mask" @click.stop="openMobileProp"></div>
				</div>
				<div class="top-bar-right text-right fr">
					<div class="top-admin">
						<div v-if="!isLogin" class="login" @click="handleLogin">
							<a href="javascript:;"><i class="icon fa fa-sign-in"></i>注册/登录</a>
						</div>
						<div v-if="isLogin" class="menu-item-has-children">
							<a href="javascript:;">
								<img class="avatar" v-lazy="userInfo.avatar">
								<span class="nickname" id="n-new" v-text="userInfo.nickName"></span>
							</a>
							<span class="toggle-btn"><i class="icon font-chevron-down"></i></span>
							<ul class="drop-down-menu">
								<li @click="handleLogin"><a href="javascript:;">
										<i class="icon fa fa-user-circle-o"></i>
										<span>个人中心</span>
									</a></li>
								<li @click="logout">
									<a href="javascript:;">
										<i class="icon fa fa-sign-out"></i>
										<span>退出登陆</span>
									</a>
								</li>
							</ul>
						</div>
					</div>
				</div>
			</div>
			<div id="percentageCounter" :style="'width: '+scrollPercent+'%;'"></div>
		</div>
	</div>
</template>

<script>
	import {
		mapGetters,
		mapMutations
	} from 'vuex'
    import {Message} from 'element-ui'
	import {
		setToken,
		getCatch
	} from "@/utils"
	import {
		logout
	} from "@/api/auth";
	export default {
		name: "web-header",
		data() {
			return {
				headerBar: [{
					text: "首页",
					icon: "fa fa-home",
					url: 'home',
					select: true
				}, {
					text: "工具",
					icon: "fa fa-book",
                    url: 'tools',
					select: false
				}, {
					text: "关于",
					icon: "fa fa-internet-explorer",
					url: 'about',
					select: false
				}],
				scrollPercent: 0
			}
		},
		methods: {
			...mapMutations(['setLoginState', 'setOpenLoginDialogState','setOpenMobilePropState']),
			headerClick(index) {
				var detail = this.headerBar[index]
				if (detail['url']) {
					for (var i = 0; i < this.headerBar.length; i++) {
						if (i === index) {
							this.headerBar[i]['select'] = true
						} else {
							this.headerBar[i]['select'] = false
						}
					}
					this.$router.push({
						name: detail['url']
					})
				}else {
				    Message.info("暂未开放")
				}
				if (this.isOpenMobileProp) {
					this.openMobileProp()
				}
			},
			handleLogin() {
				if (this.isLogin) {
			        let path = this.$route.path
					if (path == '/user'){
			            this.$message.info("当前已处于个人中心")
					} else {
                        this.$router.push({
                            name: 'user_center'
                        })
					}
				} else {
					this.setOpenLoginDialogState(true)
				}
			},
			openMobileProp() {
				this.setOpenMobilePropState(!this.isOpenMobileProp)
				document.body.className = this.isOpenMobileProp === true ? 'open' : ''
			},
			logout() {
				var _this = this
				_this.$confirm('你确定要退出吗?', '提示', {
					confirmButtonText: '确定',
					cancelButtonText: '取消',
					type: 'warning'
				}).then(() => {
					logout().then(res => {
						_this.setLoginState(false)
						_this.setOpenLoginDialogState(true)
					}).catch(error => {

					})
				}).catch(() => {

				});
			},
			scrollFunc() {
				var scrollT = document.body.scrollTop | document.documentElement.scrollTop
				var c = document.body.clientHeight
				var winH = window.innerHeight
				this.scrollPercent = parseInt(scrollT / (c - winH) * 100)
				if (this.scrollPercent > 50){
				    let topBtn = document.getElementById("bt-box-btn")
					if (topBtn){
				        topBtn.className = "bt-box top-btn-btn"
					}
				}else {
                    let topBtn = document.getElementById("bt-box-btn")
                    if (topBtn){
                        topBtn.className = ""
                    }
                }
                let rightPanel = document.getElementById("right-panel")
                let linksEle = document.getElementById("links-content")
                if (rightPanel && linksEle){
					let lastEleT =linksEle.offsetTop
					let rightH = rightPanel.offsetHeight
					if (scrollT > 100){
					    let cha = scrollT + rightH
					    if (cha < lastEleT - 80){
                            rightPanel.style.marginTop = (scrollT  - 50)+ "px"
						}
					}else {
                        rightPanel.style.marginTop =  "0px"
					}
                }
			},
            doSearch(){

			}
		},
		props: {
			routerName: {
				type: String,
				default: 'home'
			}
		},
		watch: {
			routerName(val) {
				for (var i = 0; i < this.headerBar.length; i++) {
					if (val === this.headerBar[i].url) {
						this.headerBar[i]['select'] = true
					} else {
						this.headerBar[i]['select'] = false
					}
				}
			}
		},
		computed: {
			...mapGetters(['isLogin', 'userInfo','isOpenMobileProp']),
		},
		created() {

		},
		mounted() {
            window.addEventListener('scroll',this.scrollFunc);
		}
	}
</script>

<style scoped>
	.top-admin {letter-spacing:1px;color:#555;font-size:14px;line-height:50px;display:-webkit-box;display:flex;-webkit-box-align:center;align-items:center;}
	.top-admin .login {height:55px;}
	.top-admin a {
		color:#555;
		line-height:55px;
		padding-right:10px;
		width: 100%;
		display: flex;
		align-items: center;
	}
	.top-admin a:last-child {padding-right:0;}
	.avatar {
		width: 30px;
		height: 30px;
		border-radius: 100%;
		margin-right: 5px;
	}
	.nickname{
		width: 80px;
		overflow: hidden;
		display: block;
		white-space: nowrap;
		text-overflow: ellipsis;
	}

	.menu-item-has-children {
		width: 100%;
		position: relative;
		list-style: none;
		display: block;
		float: right;
	}

	.menu-item-has-children:hover>.drop-down-menu {
		opacity: 1;
		visibility: visible;
	}

	.menu-item-has-children:hover>.drop-down-menu li {
		-webkit-transform: rotateY(0deg);
		transform: rotateY(0deg);
	}

	.drop-down-menu:before {
		width: 12px;
		height: 12px;
		position: absolute;
		content: '';
		background-color: #fff;
		border-top: 1px #efefef solid;
		border-left: 1px #efefef solid;
		z-index: 1;
		top: -6px;
		left: 12px;
		-webkit-transform: rotate(45deg);
	}

	.drop-down-menu {
		width: 100%;
		text-align: center;
		opacity: 0;
		visibility: hidden;
		position: absolute;
		top: 65px;
		left: 0px;
		border-radius: 4px;
		z-index: 100;
		background-color: #FFFFFF;
	}

	.drop-down-menu li {
		color: #448EF6;
		position: relative;
		display: block;
		-webkit-transform: rotateY(90deg);
		-moz-transform: rotateY(90deg);
		-ms-transform: rotateY(90deg);
		-o-transform: rotateY(90deg);
		transform: rotateY(90deg);
		margin-right: 0;
		padding: 10px 15px;
		list-style: none;
		border-bottom: 1px #efefef solid;
	}

	.drop-down-menu li>a {
		line-height: 30px;
	}
	.top-bar-menu{
		margin-bottom: 10px;
	}
	.top-bar-right.text-right {
		width: 14%;
	}
</style>
