export default {
    isLogin:false,
    userInfo:null,
    isOpenMusicPlayer:true,//是否显示音乐播放器
    isOpenLoginDialog: false, // 是否打开登陆登录弹框
	isOpenMobileProp:false,//是否打开手机模式的侧边栏
    musicPlayer:{
        playState:0,//是否在播放中0:暂停,1加载中,2加载完成,3播放中
        startPlay:false,//是否自动播放
        progress:'0%',//播放进度
        progressTime:'',
        volume:0,//音量
        playType:0,//切歌类型
        playData:[],//音乐数据源
        playIndex:0//播放数据索引
    },//音乐播放器
    footerIcpBei:"蜀ICP备18010183号-1",
    footerBusinessBei:"川公网安备51082302000213号",
    tokenKey:'yuan-jie-token'
}
